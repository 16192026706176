@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Cormorant+Garamond:wght@300&display=swap');

div[data-radix-scroll-area-viewport] > div {
  display: block !important;
}

input[type='date']::-webkit-calendar-picker-indicator {
  display: none;
}
